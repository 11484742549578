import React from "react";
import { medical, randomIllustration, experts } from "../assets";
import styles from "../styles";
import Button from "./Button";
import anistyles from '../animations.module.css'

const Services = () => {
  return (
    <section className={`flex flex-col py-16`}>
      <div className="flex flex-col gap-10">
        <div className="flex flex-1 justify-between sm:flex-row flex-col w-full py-16 ">
          <img
            className={`sm:w-[40%] rounded-[60px] hover:scale-105 transition-all animate-wiggle `}
            src={medical}
            alt="service pic"
          />
          <div className="flex flex-col sm:items-end items-center flex-1 animate-fadeFromBottom ">
            <h2 className={`${styles.heading2} py-6 text-white sm:text-left fit-content sm:w-[70%]`}>
              Passion for <br></br>Healthcare Excellence
            </h2>
            <p
              className={`text-poppins sm:w-[70%] ${styles.paragraph} sm:text-left text-white `}
            >
              At HealthX Group, we've cultivated an extensive network of
              contacts, clients, and resources. This enables us to support our
              professionals in advancing their careers and fill any service
              voids in healthcare organizations, thereby ensuring top-tier
              patient care.
            </p>
            {/* <Button styles={`bg-amber-400 mx-4 hover:bg-amber-400/[0.7]`}/> */}
          </div>
        </div>
        <div className="flex flex-1 justify-between sm:flex-row-reverse flex-col w-full ">
          <img
            className="sm:w-[40%] rounded-[60px] hover:scale-105 transition-all animate-wiggle"
            src={experts}
            alt="service pic"
          />
          <div className="flex flex-col sm:items-start items-center flex-1">
            <h2 className={`${styles.heading2} py-6 sm:text-left text-white animate-fadeFromBottom`}>
              Decades of Dedication: <br></br>Your Trust, Our Experience
            </h2>
            <p
              className={`text-poppins sm:w-[70%] ${styles.paragraph} mb-14 sm:text-left text-white animate-wiggle`}
            >
              Our extensive experience in healthcare and recruitment has
              underscored the significance of matching each candidate with the
              ideal role. At HealthX Group, we prioritise understanding our
              candidates in-depth, comprehending their abilities, immediate
              needs, and long-term career goals. This approach ensures we create
              the ideal match for all parties involved!
            </p>
            <Button
              styles={`bg-secondary hover:bg-yellow-500`}
              text={`Find out more`}
              link="/services"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
