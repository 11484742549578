import React from "react";
import styles, { layout } from "../styles";
import { features } from "../constants";
import { logonobg } from "../assets";

const FeatureCard = ({ icon, title, content, index }) => (
  <div
    className={`flex flex-1 flex-row p-6 rounded-[20px] ${
      index !== features.length - 1 ? "mb-6" : "mb-0"
    } feature-card bg-accent-blue hover:bg-amber-400 hover:scale-105 transition-all animate-wiggle items-center`}
  >
    <div className={`w-[64px] h-[64px] rounded-full ${styles.flexCenter}`}>
      <img
        src={icon}
        alt="star"
        className="w-[50%] h-[50%] object-contain"
      />
    </div>
    <div className="flex-1 flex flex-col ml-3">
      {/* <h4 className="font-poppins font-semibold text-white text-[18px] leading-[23.4px] mb-1">
        {title}
      </h4> */}
      <p className="font-poppins font-normal text-[16px] text-white leading-[24px]">
        {content}
      </p>
    </div>
  </div>
);

const AboutUs = () => {
  return (
    <section className={`flex flex-col py-16 my-10`}>
      <div className="flex sm:flex-row flex-col justify-between sm:items-start items-center">
        <div className="flex flex-1 flex-col items-center sm:items-start">
          <h2 className={`${styles.heading2} py-6 text-white sm:text-left`}>
            Masters of Our Craft: <br></br>Unveiling Our Disciplines
          </h2>
          <p
            className={`flex-1 font-poppins font-normal sm:text-[19px] text-[16px] w-[80%] text-white sm:text-left ${styles.paragraph} pb-10`}
          >
            Boasting over three decades of combined expertise in healthcare and
            recruitment, we ensure satisfaction for clients, candidates, and
            patients at every step.{" "}
          </p>
        </div>
        <div className={` flex-col sm:max-w-[40%] max-w-[90%]`}>
          {features.map((feature, index) => (
            <FeatureCard key={feature.id} {...feature} index={index} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default AboutUs;
