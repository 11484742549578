import React from "react";
import { Link } from "react-router-dom";

const Button = ({ styles, text, link, handleClick }) => (
  <Link to={link}>
    <button
      type="button"
      className={`py-4 px-6 font-poppins font-medium text-[18px] text-primary rounded-[10px] outline-none hover:scale-105 transition-all ${styles}`}
      onClick={handleClick}
    >
      {text}
    </button>
  </Link>
);

export default Button;
