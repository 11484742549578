import React from "react";
import styles from "../styles";
import { disciplines } from "../constants";
import { useState } from "react";

const CoolInfoBlock = () => {
  const [isActive, setActive] = useState("AHP");
  return (
    <section
      className={`flex flex-col sm:p-16 p-8 my-10 bg-accent-blue rounded-[10px]`}
    >
      <div className={`flex sm:flex-row flex-col justify-between`}>
        <div className={``}>
          <ul className="flex flex-col">
            {disciplines.map((discipline, index) => (
              <li
                key={discipline.id}
                className={`${styles.heading3} text-white cursor-pointer py-4 ${
                  isActive === discipline.title ? "border-b-2 rounded-[6px] border-secondary" : ""
                }`}
                onClick={() => {
                  setActive(discipline.title);
                }}
              >
                <h3>{discipline.title}</h3>
              </li>
            ))}
          </ul>
        </div>
        <div className="sm:max-w-[60%] text-left">
          <div
            className={`${
              isActive === "AHP" ? "flex flex-col" : "hidden"
            } text-white animate-fadeFromBottom`}
          >
            <h3 className={`${styles.heading3} py-2`}>AHP</h3>
            <p className={`${styles.paragraph} py-2`}>
              At HealthX, we prioritise ensuring that all our AHP Nurses possess not
              only a deep passion for care but also the patience crucial for
              assisting patients, many of whom are in their own homes or
              residential care facilities. The ability to effectively teach and
              listen is highly valued in our team, as our professionals often
              undertake the role of educating others in fundamental healthcare
              practices.{" "}
            </p>
            {/* <p className={`${styles.paragraph} py-2`}>
              We take pride in dispatching only the most proficient clinical
              care agency nurses, support workers, and healthcare professionals.
              Our team is selected for their extensive training, clinical
              expertise, and a heartfelt commitment to fostering patients'
              independence and dignity. Whether in the comfort of a patient's
              home or in a residential care setting, our staff is dedicated to
              providing compassionate, high-quality care.{" "}
            </p>
            <div>
              <p className={`${styles.paragraph} py-2`}>
                XXX provides Allied Health Professions (AHPs) solutions across
                Essex in both the NHS and private settings. We specialise in:{" "}
              </p>
              <ul></ul>
            </div>
            <p className={`${styles.paragraph}py-2`}>
              No matter if you're looking for a temporary, part-time position or
              a long-term, permanent contract, we offer a diverse array of
              opportunities in both public and private healthcare environments.
              To explore these roles, simply register your details here ([LINK
              TO QUICK REGISTRATION]), and one of our experienced consultants
              will contact you soon.{" "}
            </p> */}
          </div>
          <div
            className={`${
              isActive === "Nursing" ? "flex flex-col" : "hidden"
            } text-white animate-fadeFromBottom`}
          >
            <h3 className={`${styles.heading3} py-2`}>Nursing</h3>
            <p className={`${styles.paragraph} py-2`}>
              At HealthX, we recognise the pivotal role nurses play in providing
              compassionate, skilled, and dedicated care. Our commitment is to
              connect exceptional nursing professionals with opportunities that
              not only match their expertise but also align with their passion
              for making a real difference in people's lives.
            </p>
            {/* <div>
              <p className={`${styles.paragraph}`}>
                XXX is recruiting for Mental Health Professionals, with an array
                of employment types. From last-minute emergency cover to fixed
                term, permanent contracts across Essex. Our services include:
              </p>
              <ul></ul>
            </div>
            <p className={`${styles.paragraph}`}>
              Our team, comprised of skilled recruitment consultants with
              extensive experience, is dedicated to providing exceptional
              assistance in sourcing permanent nursing job vacancies. With a
              deep understanding of the temporary and locum healthcare sector,
              our consultants are committed to finding the ideal opportunities
              for our candidates. Register your details here (LINK TO QUICK
              REGISTRATION) and one of our skilled consultants will be in touch.
            </p> */}
          </div>
          <div
            className={`${
              isActive === "Mental Health Nursing" ? "flex flex-col" : "hidden"
            } text-white animate-fadeFromBottom`}
          >
            <h3 className={`${styles.heading3} py-2`}>Mental Health Nursing</h3>
            <p className={`${styles.paragraph} py-2`}>
              Our mental health nurses at HealthX are highly qualified
              professionals, well-versed in the theories of mental health
              illnesses. They skilfully apply this knowledge in practical
              settings, combining their expertise with warmth and empathy to
              comfort patients in distress. With comprehensive training, they
              are well-prepared to handle the potential risks associated with
              their roles. Recognising the unpredictable nature of a patient's
              mental state, which can sometimes lead to violent behaviour, our
              nurses are adept at identifying escalating tensions. They possess
              the essential communication skills and techniques needed to
              de-escalate situations effectively when required.
            </p>
            {/* <div>
              <p className={`${styles.paragraph}`}>
                XXX is recruiting for Mental Health Professionals, with an array
                of employment types. From last-minute emergency cover to fixed
                term, permanent contracts across Essex. Our services include:
              </p>
              <ul></ul>
            </div>
            <p className={`${styles.paragraph}`}>
              Our team consists of experienced recruitment consultants with a
              deep knowledge of the healthcare industry, dedicated to finding
              employment opportunities for our candidates. To get started,
              register your details here ([LINK TO QUICK REGISTRATION]) and one
              of our talented consultants will reach out to you.
            </p> */}
          </div>
          <div
            className={`${
              isActive === "Learning Disability" ? "flex flex-col" : "hidden"
            } text-white animate-fadeFromBottom`}
          >
            <h3 className={`${styles.heading3} py-2`}>Learning Disability</h3>

            <p className={`${styles.paragraph} py-2`}>
              Our goal is to make sure that people get the most enjoyment and
              value out of their daily lives. Whether we are doing this at home,
              at social events, in educational settings, or at work, our
              learning disabilities care team supports people in the community
              to lead an independent and fulfilling life. We advocate for a
              secure, safe, and healthy living environment.
            </p>
            {/* <p className={`${styles.paragraph}`}>
              Submit your information for a callback, whether you're in search
              of a temporary or permanent position. We collaborate with local
              authorities and independent services, dedicated to helping you
              secure your next job opportunity.
            </p> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default CoolInfoBlock;
