import React from "react";
import styles from "../styles";
import Header from "../components/Header";
import ContactUs from "../components/ContactUs";
import Footer from "../components/Footer";

const Contact = () => {
  window.scrollTo(0, 0);
  return (
    <div className="bg-primary text-center w-full overflow-hidden">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} bg-primary w-full fixed z-[1000] border-secondary border-b-[1px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Header />
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative top-[100px] min-h-[70vh]`}>
        <div className={`${styles.boxWidth}`}>
          <ContactUs />
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative bottom-[0px]`}>
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Contact;
