import React from "react";
import styles from "../styles";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { jobListings } from "../constants";
import JobList from "../components/JobList";
import { Outlet } from "react-router-dom";
import Button from "../components/Button";

const JobCard = ({
  title,
  descriptionsm,
  icon,
  descriptionbig,
  index,
  jobLink,
  salary,
  location,
}) => (
  <div
    className={`flex flex-1 flex-col p-6 rounded-[20px] ${
      index !== jobListings.length - 1 ? "mb-6" : "mb-0"
    }  bg-accent-blue hover:bg-accent-blue/[0.4] hover:scale-105 transition-all`}
  >
    <div className="flex items-center py-2">
      <img className="w-[64px]  py-3 " src={icon} alt="service" />
      <h3 className={`${styles.heading3} text-white px-3`}>{title}</h3>
    </div>
    <div className="flex flex-col items-start">
      <p className={`${styles.paragraph} text-white py-3`}>{descriptionsm}</p>
      <p className={`${styles.paragraph} text-white `}>
        <span className="text-secondary">Salary:</span> {salary}
      </p>
      <p className={`${styles.paragraph} text-white `}>
        {" "}
        <span className="text-secondary">Location:</span> {location}
      </p>
    </div>

    <Button styles={`bg-secondary my-5`} text="Find out more" link={jobLink} />
  </div>
);

const JobsPage = () => {
  window.scrollTo(0, 0);
  return (
    <div className="bg-primary text-center w-full overflow-hidden">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} bg-primary w-full fixed z-[1000] border-secondary border-b-[1px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Header />
        </div>
      </div>
      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative top-[100px] py-16`}
      >
        <div className={`${styles.boxWidth} flex items-center gap-10`}>
          <div className={`flex justify-center flex-col`}>
            {jobListings.map((job, index) => (
              <JobCard
                key={job.id}
                {...job}
                index={index}
                jobLink={`/jobs/${job.id}`}
                jobName={job.title}
              />
            ))}
          </div>
          <Outlet />
        </div>
      </div>
      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative top-[100px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default JobsPage;
