import React from "react";
import styles, { layout } from "../styles";
import { jobListings } from "../constants";
import Button from "./Button";
import { Link } from "react-router-dom";

const JobCard = ({
  title,
  descriptionsm,
  icon,
  descriptionbig,
  index,
  jobLink,
  salary,
  location,
}) => (
  <div
    className={`flex flex-1 flex-col p-6 rounded-[20px] ${
      index !== jobListings.length - 1 ? "mr-6" : "mb-0"
    }  bg-accent-blue hover:bg-accent-blue/[0.4] hover:scale-105 transition-all`}
  >
    <div className="flex items-center py-2">
      <img className="w-[64px]  py-3 " src={icon} alt="service" />
      <h3 className={`${styles.heading3} text-white px-3`}>{title}</h3>
    </div>
    <div className="flex flex-col items-start">
      <p className={`${styles.paragraph} text-white py-3`}>{descriptionsm}</p>
      <p className={`${styles.paragraph} text-white `}><span className="text-secondary">Salary:</span> {salary}</p>
      <p className={`${styles.paragraph} text-white `}> <span className="text-secondary">Location:</span> {location}</p>
    </div>

    <Button styles={`bg-secondary my-5`} text="Find out more" link={jobLink} />
  </div>
);

const JobList = (props) => {
  return (
    <section
      className={`flex flex-col py-16 my-10 ${
        jobListings.length > 0 ? "" : "hidden"
      }`}
    >
      <div className={``}>
        <h2 className={`${styles.heading2} text-white py-10`}>
          Here are some vacancies
        </h2>
        <div className={`flex ${props.style} sm:flex-row flex-col justify-center gap-4`}>
          {jobListings.map((job, index) => (
            <JobCard
              key={job.id}
              {...job}
              index={index}
              jobLink={`/jobs/${job.id}`}
            />
          ))}
        </div>
      </div>
    </section>
  );
};

export default JobList;
