import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import styles from "../styles";
import CoolLayout from "../components/CoolLayout";
import Button from "../components/Button";
import { experts, notFound } from "../assets";

const NotFound = () => {
  return (
    <div className="bg-primary text-center w-full overflow-hidden">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} bg-primary w-full fixed z-[1000] border-secondary border-b-[1px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Header />
        </div>
      </div>

      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative top-[100px]`}
      >
        <div className={`${styles.boxWidth} py-10`}>
        <div className="flex flex-1 justify-between sm:flex-row-reverse flex-col w-full h-[80vh] items-center">
          <img
            className="sm:w-[40%] rounded-[60px] hover:scale-105 transition-all"
            src={notFound}
            alt="service pic"
          />
          <div className="flex flex-col sm:items-start items-center flex-1">
            <h2 className={`${styles.heading2} py-6 sm:text-left text-white`}>
              Oops, it looks like you've found thw wrong page
            </h2>
          </div>
        </div>
            <Footer />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
