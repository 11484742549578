import React from "react";
import Header from "../components/Header";
import Hero from "../components/Hero";
import Services from "../components/Services";
import AboutUs from "../components/AboutUs";
import styles from "../styles";
import CoolLayout from "../components/CoolLayout";
import ThreeMainPoints from "../components/ThreeMainPoints";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import JobList from "../components/JobList";
import CoolInfoBlock from "../components/CoolInfoBlock";

// xl:max-w-[1280px] xl

const Home = () => {
  window.scrollTo(0, 0);
  return (
    <div className="bg-primary text-center w-full overflow-hidden">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} bg-primary w-full fixed z-[1000] border-secondary border-b-[1px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Header />
        </div>
      </div>
      <div className="relative top-[100px] mb-20">
        <div className="bg-hero-image bg-cover bg-center z-[-1] ">
          <div
            className={`bg-gradient-to-b from-primary to-primary/[0.65] ${styles.flexCenter}`}
          >
            <div className="xl:max-w-[1280px] w-full sm:px-16 px-6 pb-16">
              <Hero />
            </div>
          </div>
        </div>
      </div>

      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <Services />
          {/* <AboutUs /> */}
        </div>
      </div>
      <div className={`bg-primary ${styles.paddingX} ${styles.flexStart}`}>
        <div className={`${styles.boxWidth}`}>
          <CoolInfoBlock />
          <JobList />
          <ContactUs />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Home;
