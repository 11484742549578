import React from "react";
import { useParams } from "react-router-dom";
import { jobListings } from "../constants";

const JobPage = () => {
  const params = useParams();
  const job = jobListings.find((element)=> element.id === params.jobId)
  console.log(job)
  return (
    <div className="hidden sm:flex text-white"> {job.title}</div>
  );
};

export default JobPage;
