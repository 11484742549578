import React from "react";
import styles from "../styles";
import Button from "./Button";

const Hero = () => {
  return (
    <section id="home" className={`flex md:flex-row flex-col py-16 sm:py-40`}>
      <div className={`flex-1 flex flex-col xl:px-0 sm:px-16 px-6`}>
        <div className="flex flex-1 flex-col sm:items-start items-center sm:w-[50%] animate-wiggle">
          <h1
            className={`font-poppins  font-semibold text-[38px] sm:text-[56px] text-white px-4`}
          >
            Talent Unleashed.
          </h1>
          <h1
            className={`font-poppins  font-semibold text-[38px] sm:text-[56px] text-secondary px-4 `}
          >
            Careers Elevated.
          </h1>
          <p className="font-poppins text-white text-xl py-10 text-left px-4">
            HealthX Group stands as a dedicated healthcare recruitment agency,
            committed to delivering outstanding customer service.
          </p>
          <Button
            styles={`bg-secondary mx-4`}
            text={`Register with us`}
            link="/contact"
          />
        </div>
      </div>
    </section>
  );
};

export default Hero;
