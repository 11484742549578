import React from "react";
import { email, onlineResume, phone, updatedResume } from "../assets";
import styles from "../styles";
import { useState } from "react";
import Button from "./Button";

const ContactUs = () => {
  const [contactPage, setContactPage] = useState("candidate");
  const [name, setName] = useState("");
  const [emailsub, setEmailsub] = useState("");
  const [number, setNumber] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    formData.append("access_key", "0f4dbb77-4849-4624-ab4b-e0ce34a8c7e6");

    const object = Object.fromEntries(formData);
    const json = JSON.stringify(object);

    const res = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: json,
    }).then((res) => res.json());

    if (res.success) {
      console.log("Success", res);
    }
    setEmailsub("");
    setName("");
    setMessage("");
    setNumber("");
  };

  return (
    <section className={`flex flex-col py-16 my-10`} id="contact">
      {/* <div>
        <button
          className={`bg-secondary text-white p-2 rounded-[5px]`}
          onClick={() => {
            setContactPage("client");
          }}
        >
          Client
        </button>
        <button
          className={`bg-secondary text-white p-2 rounded-[5px]`}
          onClick={() => {
            setContactPage("candidate");
          }}
        >
          Candidate
        </button>
      </div> */}
      <div
        className={`${
          contactPage === "candidate" ? "flex flex-col sm:flex-row" : "hidden"
        } justify-around animate-fadeFromBottom`}
      >
        <div className="sm:w-[40%] flex flex-col justify-center sm:items-start">
          <h2 className={`${styles.heading2} text-left text-white`}>
            Get in Touch:<br></br> Where Solutions Begin with a Hello!
          </h2>
          <img className="h-[300px] py-8" src={updatedResume} />
          <div className="flex flex-col items-start">
            <div className="flex items-center justify-center pb-6">
              <div className="object-contain ">
                <img className=" w-[40px]" src={email} alt="" />
              </div>
              <div className="flex flex-col items-start px-6 ">
                <p className={`{${styles.paragraph} text-white`}>
                  Email us here
                </p>
                <p className={`${styles.paragraph} text-secondary `}>
                  info@healthxgroup.co.uk
                </p>
              </div>
            </div>
            <div className="flex items-center justify-center">
              <div className="object-contain">
                <img className=" w-[40px]" src={phone} alt="" />
              </div>
              <div className="flex flex-col items-start px-6">
                <p className={`{${styles.paragraph} text-white`}>Phone us on</p>
                <p className={`${styles.paragraph} text-secondary `}>
                  01206 803755
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center sm:w-[40%] mb-10">
          <h3 className={`${styles.heading3} text-white py-5`}>
            Get in touch, let's make things happen
          </h3>
          <form
            className="flex flex-col w-[100%] items-start"
            onSubmit={handleSubmit}
          >
            <input
              className="w-full my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="text"
              name="fullname"
              value={name}
              id="fullname"
              placeholder="Full name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              required
            ></input>
            <input
              className=" w-full my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="email"
              name="email"
              value={emailsub}
              id="email"
              placeholder="Email"
              onChange={(e) => {
                setEmailsub(e.target.value);
              }}
              required
            ></input>
            <input
              className="w-full  my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="phone"
              name="phone"
              value={number}
              id="phone"
              placeholder="Phone Number"
              onChange={(e) => {
                setNumber(e.target.value);
              }}
              required
            ></input>
            <textarea
              className="my-5 rounded-[10px] w-[60%] p-2 focus:outline-secondary border-8 border-accent-blue hover:scale-105 transition-all"
              placeholder="Send us a message"
              name="message"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            ></textarea>
            <input
              className="text-primary bg-secondary py-2 px-4 rounded-[10px] cursor-pointer hover:scale-105 transition-all"
              type="submit"
            ></input>
          </form>
        </div>
      </div>
      {/* <div
        className={`${
          contactPage === "client" ? "flex" : "hidden"
        } justify-around animate-fadeFromBottom`}
      >
        <div className="w-[40%] flex flex-col items-start justify-center">
          <h2 className={`${styles.heading2} text-left text-white`}>
            Get in Touch:<br></br> Where Solutions Begin with a Hello!
          </h2>
          <img className="h-[300px] py-8" src={updatedResume} />
          <div className="flex items-center pb-8">
            <div className="object-contain">
              <img className=" w-[40px]" src={email} alt="" />
            </div>
            <div className="flex flex-col items-start px-6">
              <p className={`{${styles.paragraph} text-white`}>Email us here</p>
              <p className={`${styles.paragraph} text-secondary `}>
                info@healthxgroup.co.uk
              </p>
            </div>
          </div>
          <div className="flex items-center">
            <div className="object-contain">
              <img className=" w-[40px]" src={phone} alt="" />
            </div>
            <div className="flex flex-col items-start px-6">
              <p className={`{${styles.paragraph} text-white`}>Phone us on</p>
              <p className={`${styles.paragraph} text-secondary `}>
                info@healthxgroup.co.uk
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center w-[40%] mb-10">
          <h3 className={`${styles.heading3} text-white py-5`}>
            generic call to action
          </h3>
          <form className="flex flex-col w-[100%] items-start" action="">
            <input
              className="w-full my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="text"
              name="fullname"
              value={``}
              id="fullname"
              placeholder="Full name"
              required
            ></input>
            <input
              className="w-full my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="text"
              name="company"
              value={``}
              id="company"
              placeholder="Company"
              required
            ></input>
            <input
              className=" w-full my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="email"
              name="email"
              value={``}
              id="email"
              placeholder="Email"
              required
            ></input>
            <input
              className="w-full  my-5 rounded-[10px] p-2 focus:outline-secondary hover:scale-105 transition-all border-8 border-accent-blue"
              type="number"
              name="number"
              value={``}
              id="number"
              placeholder="Phone Number"
              required
            ></input>
            <textarea
              className="my-5 rounded-[10px] w-[60%] p-2 focus:outline-secondary border-8 border-accent-blue"
              placeholder="Send us a message"
            ></textarea>
            <input
              className="text-primary bg-secondary py-2 px-4 rounded-[10px] cursor-pointer "
              type="submit"
            ></input>
          </form>
        </div>
      </div> */}
      <div className="flex justify-center">
        {/* <iframe
          className="rounded-[20px] w-[80%] mt-10"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d953.5048792410552!2d0.8926537083672014!3d51.888046755867556!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d904f69d5c679d%3A0x36bd6c694c541264!2s49%20Crouch%20St%2C%20Colchester%20CO3%203EN!5e0!3m2!1sen!2suk!4v1708008388333!5m2!1sen!2suk"
          width="600"
          height="450"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe> */}
      </div>
    </section>
  );
};

export default ContactUs;
