import React from "react";
import { navLinks } from "../constants";
import { logonobg, menu, close } from "../assets";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";

const Header = () => {
  const [toggle, setToggle] = useState(false);
  const handelClick = () => {
    setToggle((prev) => !prev);
  };
  return (
    <>
      <div className="text-secondary flex justify-between items-center w-full py-3">
        <Link to="/">
          <img src={logonobg} alt="logo" className="w-[100px]" />
        </Link>

        <ul className="list-none sm:flex hidden justify-end items-center flex-1">
          {navLinks.map((link, index) => (
            <li
              key={link.id}
              className={`text-white hover:text-secondary transition font-normal cursor-pointer font-poppins ${
                index === navLinks.length - 1 ? "mr-0" : "mr-10"
              }`}
            >
              <NavLink to={link.url}>{link.title}</NavLink>
            </li>
          ))}
        </ul>
        <div className="sm:hidden flex flex-1 justify-end items-center">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[30px] h-[28px] object-contain"
            onClick={handelClick}
          />
          <div
            className={`${
              toggle ? "flex" : "hidden"
            } py-6 bg-accent-blue absolute top-20 right-[0px]  min-w-[140px] sidebar w-full animate-fadeFromBottom `}
          >
            <ul className="list-none flex justify-center items-center flex-1">
              {navLinks.map((link, index) => (
                <li
                  key={link.id}
                  className={`text-white font-normal cursor-pointer ${
                    index === navLinks.length - 1 ? "mr-0" : "mr-4"
                  }`}
                >
                  <NavLink to={link.url}>{link.title}</NavLink>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <ul className="list-done flex">
          {navLinks.map((link) => (
            <li
              key={`${link.id}`}
              className="text-white capitalize hidden px-3"
            >{`${link.title}`}</li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Header;
