import React from "react";
import Header from "../components/Header";
import styles from "../styles";
import AboutUs from "../components/AboutUs";
import Services from "../components/Services";
import Footer from "../components/Footer";
import ContactUs from "../components/ContactUs";
import CoolInfoBlock from "../components/CoolInfoBlock";
import CoolLayout from "../components/CoolLayout";
import { doctor, logonobg, medicine, meditate, randomIllustration } from "../assets";

const About = () => {
  window.scrollTo(0, 0);
  return (
    <div className="bg-primary text-center w-full overflow-hidden">
      <div
        className={`${styles.paddingX} ${styles.flexCenter} bg-primary w-full fixed z-[1000] border-secondary border-b-[1px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <Header />
        </div>
      </div>

      <div
        className={`bg-primary ${styles.paddingX} ${styles.flexStart} relative top-[100px]`}
      >
        <div className={`${styles.boxWidth}`}>
          <AboutUs />
          {/* <h2 className={`${styles.heading2} text-white text-left`}>Our Disciplines</h2> */}
          <section className="flex justify-between py-10 sm:flex-row flex-col">
            <div className="flex flex-col sm:w-[50%]">
              <div className="py-6 pb-20">
                <h2 className={`${styles.heading2} text-white py-6 text-left`}>
                  AHPs
                </h2>
                <p className={`${styles.paragraph} text-white text-left`}>
                  We take pride in dispatching only the most proficient clinical
                  care agency nurses, support workers, and healthcare
                  professionals. Our team is selected for their extensive
                  training, clinical expertise, and a heartfelt commitment to
                  fostering patients' independence and dignity. Whether in the
                  comfort of a patient's home or in a residential care setting,
                  our staff is dedicated to providing compassionate,
                  high-quality care.
                </p>
              </div>
              <div className="flex">
                <div className="flex-1 hover:scale-105 transition-all text-white flex flex-col items-start">
                  <h3
                    className={`${styles.heading3} text-white py-6 text-left`}
                  >
                    Your Health Advocates
                  </h3>
                  <p
                    className={`${styles.paragraph} text-white text-left w-[90%]`}
                  >
                    HealthX provides Allied Health Professions (AHPs) solutions
                    across Essex in both the NHS and private settings. Our
                    services include:
                  </p>
                  <ul className="text-left py-6 ml-10">
                    <li>
                      <span className="text-secondary">- </span>
                      <span className="text-secondary"></span> Occupational
                      therapists
                    </li>
                    <li>
                      <span className="text-secondary">- </span> Speech and
                      Language therapists{" "}
                    </li>
                    <li>
                      <span className="text-secondary">- </span>{" "}
                      Physiotherapists{" "}
                    </li>
                    <li>
                      <span className="text-secondary">- </span> Radiographers{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sm:w-[40%] ">
              <img
                className="rounded-[10px] h-[100%]"
                src={doctor}
                alt="image"
              />
            </div>
          </section>
          <section className="flex justify-between py-10 sm:flex-row flex-col text-white">
            <div className="flex flex-col sm:w-[50%]">
              <div className="pb-20">
                <h2 className={`${styles.heading2} text-white py-6 text-left`}>
                  Mental Health Nursing
                </h2>
                <p className={`${styles.paragraph} text-white text-left`}>
                  Our mental health nurses at HealthX are highly qualified
                  professionals, well-versed in the theories of mental health
                  illnesses. They skilfully apply this knowledge in practical
                  settings, combining their expertise with warmth and empathy to
                  comfort patients in distress. With comprehensive training,
                  they are well-prepared to handle the potential risks
                  associated with their roles. Recognising the unpredictable
                  nature of a patient's mental state, which can sometimes lead
                  to violent behaviour, our nurses are adept at identifying
                  escalating tensions. They possess the essential communication
                  skills and techniques needed to de-escalate situations
                  effectively when required.
                </p>
              </div>
              <div className="flex">
                <div className="flex-1 hover:scale-105 transition-all flex flex-col items-start text-left">
                  <h3
                    className={`${styles.heading3} text-white py-6 text-left`}
                  >
                    Mind Matters
                  </h3>
                  <p
                    className={`${styles.paragraph} text-white text-left w-[90%]`}
                  >
                    HealthX is recruiting for Mental Health Professionals, with
                    an array of employment types. From last-minute emergency
                    cover to fixed term, permanent contracts across Essex. Our
                    services include:
                  </p>
                  <ul className="py-6 ml-10">
                    <li>
                      <span className="text-secondary">- </span> 1-1 Specialists
                    </li>
                    <li>
                      <span className="text-secondary">- </span> Specialist
                      Substance Misuse Nurses
                    </li>
                    <li>
                      <span className="text-secondary">- </span> Mental Health
                      nurses{" "}
                    </li>
                    <li>
                      <span className="text-secondary">- </span> Mental Health
                      HCA’s{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sm:w-[40%] ">
              <img
                className="rounded-[10px] h-[100%]"
                src={meditate}
                alt="image"
              />
            </div>
          </section>
          <section className="flex justify-between py-10 sm:flex-row flex-col">
            <div className="flex flex-col sm:w-[50%]">
              <div className="py-6 pb-20">
                <h2 className={`${styles.heading2} text-white py-6 text-left`}>
                  Nursing
                </h2>
                <p className={`${styles.paragraph} text-white text-left`}>
                  At HealthX, we recognise the pivotal role nurses play in
                  providing compassionate, skilled, and dedicated care. Our
                  commitment is to connect exceptional nursing professionals
                  with opportunities that not only match their expertise but
                  also align with their passion for making a real difference in
                  people's lives.
                </p>
              </div>
              <div className="flex">
                <div className="flex-1 hover:scale-105 transition-all text-white text-left">
                  <h3
                    className={`${styles.heading3} text-white py-6 text-left`}
                  >
                    Empathy in Action
                  </h3>
                  <p
                    className={`${styles.paragraph} text-white text-left w-[90%]`}
                  >
                    HealthX is recruiting for Mental Health Professionals, with
                    an array of employment types. From last-minute emergency
                    cover to fixed term, permanent contracts across Essex. Our
                    services include:
                  </p>
                  <ul className="py-6 ml-10">
                    <li>
                      {" "}
                      <span className="text-secondary">-</span> Healthcare
                      assistant{" "}
                    </li>
                    <li>
                      {" "}
                      <span className="text-secondary">- </span>Support workers{" "}
                    </li>
                    <li>
                      {" "}
                      <span className="text-secondary">- </span>Domiciliary Care{" "}
                    </li>
                    <li>
                      {" "}
                      <span className="text-secondary">- </span>Elderly Care{" "}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="sm:w-[40%] ">
              <img
                className="rounded-[10px] h-[100%]"
                src={medicine}
                alt="image"
              />
            </div>
          </section>

          <ContactUs />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default About;
