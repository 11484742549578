import { facebook, linkedin, twitter, instagram, logonobg, consultant, fileInvoice, fileInvoiceDollar } from "../assets";

const navLinks = [
  {
    id: "1",
    title: "Home",
    url: "/",
  },
  {
    id: "2",
    title: "Services",
    url: "/services",
  },
  // {
  //   id: "3",
  //   title: "Vacancies",
  //   url: "/jobs",
  // },
  {
    id: "4",
    title: "Contact",
    url: "/contact",
  },
];

const services = [
  {
    id: "1",
    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed doeiusmod tempor incididunt ut labore et dolore magna aliqua. Utenim ad minim veniam, quis nostrud exercitation ullamco laborisnisi ut aliquip ex ea commodo consequat.",
  },
];

const features = [
  {
    id: "feature-1",
    icon: fileInvoice,
    title: "Title",
    content: "- Compatible shift options: block or ad hoc bookings based on availability 24/7 service ",
  },
  {
    id: "feature-1",
    icon: consultant,
    title: "Title",
    content: "- A designated expert consultant",
  },
  {
    id: "feature-1",
    icon: fileInvoiceDollar,
    title: "Title",
    content: "- Easy and reliable payroll that can be adjusted to your individual needs",
  },
];
const socialMedia = [
  // {
  //   id: "social-media-1",
  //   icon: instagram,
  //   link: "https://www.instagram.com/",
  // },
  {
    id: "social-media-2",
    icon: facebook,
    link: "https://www.facebook.com/profile.php?id=61555830309668",
  },
  // {
  //   id: "social-media-3",
  //   icon: twitter,
  //   link: "https://www.twitter.com/",
  // },
  {
    id: "social-media-4",
    icon: linkedin,
    link: "https://uk.linkedin.com/company/healthxgroup",
  },
];

const footerLinks = [
  // {
  //   title: "Useful Links",
  //   links: [
  //     {
  //       name: "Content",
  //       link: "https://www.hoobank.com/content/",
  //     },
  //     {
  //       name: "How it Works",
  //       link: "https://www.hoobank.com/how-it-works/",
  //     },
  //     {
  //       name: "Create",
  //       link: "https://www.hoobank.com/create/",
  //     },
  //     {
  //       name: "Explore",
  //       link: "https://www.hoobank.com/explore/",
  //     },
  //     {
  //       name: "Terms & Services",
  //       link: "https://www.hoobank.com/terms-and-services/",
  //     },
  //   ],
  // },
  // {
  //   title: "Community",
  //   links: [
  //     {
  //       name: "Help Center",
  //       link: "https://www.hoobank.com/help-center/",
  //     },
  //     {
  //       name: "Partners",
  //       link: "https://www.hoobank.com/partners/",
  //     },
  //     {
  //       name: "Suggestions",
  //       link: "https://www.hoobank.com/suggestions/",
  //     },
  //     {
  //       name: "Blog",
  //       link: "https://www.hoobank.com/blog/",
  //     },
  //     {
  //       name: "Newsletters",
  //       link: "https://www.hoobank.com/newsletters/",
  //     },
  //   ],
  // },
  // {
  //   title: "Partner",
  //   links: [
  //     {
  //       name: "Our Partner",
  //       link: "https://www.hoobank.com/our-partner/",
  //     },
  //     {
  //       name: "Become a Partner",
  //       link: "https://www.hoobank.com/become-a-partner/",
  //     },
  //   ],
  // },
];

const jobListings = [
  // {
  //   id: "1",
  //   title: "Nurse",
  //   icon: logonobg,
  //   descriptionsm: "some brief cool description",
  //   descriptionbig: "",
  //   location:'Colchester',
  //   salary:'£50,000',
  // },
  // {
  //   id: "2",
  //   title: "Clinic Manager",
  //   icon: logonobg,
  //   descriptionsm: "some brief cool description",
  //   descriptionbig: "",
  //   location:'Colchester',
  //   salary:'£50,000',
  // },
  // {
  //   id: "3",
  //   title: "Pharmacist",
  //   icon: logonobg,
  //   descriptionsm: "some brief cool description",
  //   descriptionbig: "",
  //   location:'Colchester',
  //   salary:'£50,000',
  // },
];
const threeMainPoints = [
  {
    id: "point-1",
    icon: "star",
    title: "Title",
    content: "- A designated expert consultant",
  },
  {
    id: "point-1",
    icon: "star",
    title: "Title",
    content:
      "- Compatible shift options: block or ad hoc bookings based on availability",
  },
  {
    id: "point-1",
    icon: "star",
    title: "Title",
    content:
      "- Easy and reliable payroll that can be adjusted to your individual needs",
  },
];
const disciplines = [{
  id: "dis-1",
  icon: "star",
  title: "AHP",
  content: "",
},{
  id: "dis-2",
  icon: "star",
  title: "Mental Health Nursing",
  content: "",
},{
  id: "dis-3",
  icon: "star",
  title: "Nursing",
  content: "",
},{
  id: "dis-4",
  icon: "star",
  title: "Learning Disability",
  content: "",
},]

export {
  navLinks,
  services,
  features,
  jobListings,
  threeMainPoints,
  footerLinks,
  socialMedia,
  disciplines,
};
